import { Loader } from '@frontend-app-shell/shared/ui';
import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';

import { iframeAllow } from './iframe-allow';

export type ProductIframeType =
  | 'compliance'
  | 'investigations-graph'
  | 'investigations-storyline'
  | 'growth';

export type ActiveIframeType = ProductIframeType | '';

type Props = {
  src?: string;
  product: ProductIframeType;
  activeIframe: ActiveIframeType;
};

export const ProductIframe = ({ src, product, activeIframe }: Props) => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [documentTitle, setDocumentTitle] = React.useState('Chainalysis');
  const location = useLocation();
  const navigate = useNavigate();
  const isIframeHidden = activeIframe !== product;

  const getPathname = React.useCallback(() => {
    const parts = location.pathname.split('/');
    if (parts[1] === 'compliance')
      return `/${parts.slice(2).join('/')}${location.search}`;

    if (parts[1] === 'investigations')
      return `/${parts.slice(2).join('/')}${location.search}`;

    if (parts[1] === 'growth')
      return `/${parts.slice(2).join('/')}${location.search}`;

    return `${location.pathname}${location.search}`;
  }, [location]);

  // DO NOT REMOVE THIS LINE - used as a initial src value of the iframe to prevent iframe full page reload
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const srcPath = React.useMemo(getPathname, []);

  const onLoad = () => {
    setIsLoading(false);
    iframeRef.current?.contentWindow?.focus();
  };

  const getTargetOrigin = React.useCallback(() => {
    switch (product) {
      case 'compliance':
        return import.meta.env.VITE_KYT_FRONTEND_URL;
      case 'investigations-graph':
        return import.meta.env.VITE_REACTOR_FRONTEND_URL;
      case 'investigations-storyline':
        return import.meta.env.VITE_STORYLINE_FRONTEND_URL;
      case 'growth':
        return import.meta.env.VITE_PLAYBOOK_FRONTEND_URL;
      default:
        return '';
    }
  }, [product]);

  const routeIframe = React.useCallback(() => {
    if (activeIframe !== product) return;
    else if (activeIframe === product) {
      iframeRef.current?.contentWindow?.postMessage(
        {
          message: 'APP_SHELL_ROUTE_ON_CHANGE',
          data: {
            url: getPathname(),
          },
        },
        getTargetOrigin(),
      );
    }
  }, [activeIframe, product, getPathname, getTargetOrigin]);

  React.useEffect(() => {
    const onRouteChange = (event: MessageEvent) => {
      const productName = product.toUpperCase();

      switch (event.data.message) {
        case 'COMPLIANCE_ON_ROUTE_CHANGE': {
          navigate(`/compliance${event.data.data.url}`, { replace: true });

          break;
        }
        case 'INVESTIGATIONS-STORYLINE_ON_ROUTE_CHANGE': {
          navigate(`/investigations${event.data.data.url}`, { replace: true });
          break;
        }
        case 'INVESTIGATIONS-GRAPH_ON_ROUTE_CHANGE': {
          navigate(`/investigations${event.data.data.url}`, { replace: true });
          break;
        }
        case 'GROWTH_ON_ROUTE_CHANGE': {
          navigate(`/growth${event.data.data.url}`, { replace: true });
          break;
        }
        case `${productName}_ON_DOCUMENT_TITLE_CHANGE`: {
          setDocumentTitle(event.data.data.documentTitle);
          break;
        }
        case `${productName}_ON_LOGOUT`: {
          window.location.assign(
            `${import.meta.env.VITE_LOGOUT_URL}?redirect=${
              window.location.href
            }`,
          );
          break;
        }
        case `${productName}_ON_LOGIN`: {
          window.location.assign(
            `${import.meta.env.VITE_LOGIN_URL}?redirect=${
              window.location.href
            }}`,
          );
          break;
        }
        case `${productName}_ON_CHANGE_WINDOW_LOCATION`: {
          window.location.assign(event.data.data.url);
          break;
        }
        default: {
          // if the message is not recognized, log it to the console so it propagates to datadog
          // some apps like pendo might send messages that we don't care about
          if (event.data.message) {
            console.error('Unknown message', event.data.message);
          }
          break;
        }
      }
    };

    window.addEventListener('message', onRouteChange);

    return () => window.removeEventListener('message', onRouteChange);
  }, [navigate, product]);

  React.useEffect(() => {
    if (!isLoading) routeIframe();
  }, [routeIframe, isLoading]);

  if (!src) return <Box>Missing source</Box>;

  return (
    <>
      <Helmet title={documentTitle} />
      <Box
        sx={{
          opacity: isIframeHidden ? 0 : 1,
          height: isIframeHidden ? '0' : '100%',
          transition: 'opacity 0.1s linear',
          transitionDelay: '0.2s',
        }}
      >
        {isLoading && <Loader variant="container" />}
        <Box display={isLoading ? 'none' : ''} height="100%" width="100%">
          <iframe
            ref={iframeRef}
            title={product}
            src={`${src}${srcPath}`}
            onLoad={onLoad}
            style={{ display: 'block' }}
            name={`app-shell-${product}-${new Date().getTime()}`}
            allow={iframeAllow}
          />
        </Box>
      </Box>
    </>
  );
};
